<template>
  <div>
    <b-card no-body class="mb-4 p-3">
      <div class="mb-3">
        <b-button variant="primary" v-if="permissao.criar" @click="abrirCadastrar">{{
            $t("ROTULO_BOLETOS.CRIAR_ROTULO")
        }}</b-button>
      </div>
    </b-card>

    <valorem-tabela
        sem-selecionar-todos
        backend-paginado
        em-card
        ref="tabela"
        v-model="tabela.dados"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        :responsiva="false"
        @paginar="listar"
    >
        <template v-slot:cell(acoes)="item">
          <div class="flex">
            <b-dropdown variant="outline-default">
              <b-dropdown-item v-if="permissao.editar" @click="abrirEditar(item.item.id)">
                {{$t("GERAL.EDITAR")}}
              </b-dropdown-item>
              <b-dropdown-item v-if="permissao.deletar" @click="excluir(item.item.id)">
                {{ $t("GERAL.EXCLUIR") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
    </valorem-tabela>
  </div>
</template>

<script>
import RotuloBoletosService from "@/common/services/rotulo-boletos/rotulo-boletos.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";

import helpers from "../../common/utils/helpers";

export default {
  name: "RotuloBoletosIndex",
  data: function () {
    return {
      filtro: "",
      tabela: {
        dados: [],
        colunas: [
          { key: "codigo", label: this.$t("ROTULO_BOLETOS.TABELA.CODIGO") },
          { key: "nome", label: this.$t("ROTULO_BOLETOS.TABELA.NOME") },
          { key: "acoes", label: this.$t("GERAL.ACOES") },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 10,
      }
    };
  },
  mounted() {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: this.$t("MENU.ROTULO_BOLETOS") }]);
      this.listar(this.tabela.paginaAtual, this.tabela.porPagina);
  },
  methods: {
    listar: function (paginaAtual, porPagina) {
      this.$store.dispatch(START_LOADING);
      
      RotuloBoletosService.listar({
        numeroDaPagina: paginaAtual,
        tamanhoDaPagina: porPagina,
      })
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens = res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirCadastrar: function() {
      this.$router.push({ name: "rotulo-boletos-novo" });
    },
    abrirEditar: function(id) {
      this.$router.push({ name: "rotulo-boletos-editar", params: { id } });
    },
    excluir: function(id) {
      console.log(id);
      return new Promise((/*resolve*/) => {
        this.confirmar(
          this.$t(`ROTULO_BOLETOS.TITULO_EXCLUIR`),
          this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
        ).then((confirmado) => {
          if (!confirmado) 
            return;

          this.$store.dispatch(START_LOADING);
          RotuloBoletosService.excluir(id)
            .then(() => {
              this.$router.go();
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      });
    }
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("RotuloBoletos");
    },
  }
}
</script>